import React,{useState} from 'react'
import { PaystackButton } from 'react-paystack'
import {useNavigate} from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import styles from '../landingpage/Donate.module.scss'

function Paystack({amount}) {

  const navigate = useNavigate();
  const publicKey = process.env.REACT_APP_PAYSTACK_PKEY
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")

  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Make an Impact",
    onSuccess: (res) =>{
      console.log(res)
        navigate(`/payment-success`)
    },
    onClose: () => alert("Payment is failure"),
  }


  return <>
  {/* <Form.Control aria-label="Enter amount" value={amount} className={`${styles.currencyTextBox} fs--26 fontMontserrat fw--700`} /> */}
      <div className="checkout-form">
  <div className="checkout-field mt10">
    {/* <label>Name</label> */}
    <input
      type="text"
      placeholder='Name'
      id="name"
      onChange={(e) => setName(e.target.value)}
      className={`${styles.textBox} radio-alias`}
      style={{marginTop:"10px"}}
    />
  </div>
  <div className="checkout-field">
    {/* <label>Email</label> */}
    <input
      type="text"
      id="email"
      placeholder='Email'
      onChange={(e) => setEmail(e.target.value)}
      className={`${styles.textBox} radio-alias`}
      style={{marginTop:"10px"}}    />
  </div>
  <div className="checkout-field">
    {/* <label>Phone</label> */}
    <input
      type="text"
      placeholder='Phone'
      id="phone"
      onChange={(e) => setPhone(e.target.value)}
      // className={`${styles.currencyTextBox} fs--26 fontMontserrat fw--700`}
      className={`${styles.textBox} radio-alias`}
      style={{marginTop:"10px"}}
    />
  </div>
  
  <PaystackButton className="btn--primary py-2 border-0 mt30" {...componentProps} />
</div>
  </>
}

export default Paystack